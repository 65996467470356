var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"sm":"12","md":"6"}},[_c('b-img',{attrs:{"src":require('@/assets/images/pages/coming-soon.svg')}})],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-card',[_c('b-card-title',[_vm._v(" Crear nuevo usuario ")]),_c('validation-observer',{ref:"formValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"text"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"WhatsApp"}},[_c('validation-provider',{attrs:{"name":"WhatsApp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"number"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Referido ( Opcional )"}},[_c('validation-provider',{attrs:{"name":"Referido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"text"},model:{value:(_vm.user.referred_by),callback:function ($$v) {_vm.$set(_vm.user, "referred_by", $$v)},expression:"user.referred_by"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.loading,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('add', { type: '' }))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }